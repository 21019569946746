.container {
	height: 756px;

	.left {
		flex: 1;
		padding-right: 16px;
		overflow-y: auto;
	}

	.divider {
		height: 100%;
		border-color: var(--dark-theme-border);
		margin: 0;
	}

	.right {
		flex: 1;
		padding-left: 16px;
		height: 100%;
	}
}

@media (max-width: 768px) {
	.container {
		flex-direction: column;
		height: unset;

		.left {
			overflow-y: hidden;
			padding-right: 0px;
		}

		.divider {
			display: none;
		}

		.right {
			padding-left: 0px;
		}
	}
}
