@mixin defaultTitle {
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
}

@mixin defaultSubTitle {
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
}

@mixin formLabel {
	font-size: 16px !important;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
}

@mixin buttonText {
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
}

@mixin defaultText {
	color: var(--corvus-black);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 36px;
}

@mixin defaultTextSmall {
	color: var(--corvus-black);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 30px;
}
