.container {
	width: 100%;
	padding: 8px 16px;
	background-color: var(--corvus-green-light);
	border: 1px solid var(--light-green);
	border-radius: 8px;

	[class^='ant-checkbox-inner'] {
		width: 24px;
		height: 24px;

		&::after {
			width: 8px;
			height: 13px;
		}
	}

	[class^='ant-form-item'] {
		margin-bottom: 0;
	}

	.checkbox {
		font-size: 22px;
		font-weight: 600;
		color: var(--dark-emerald);
	}

	.text {
		color: var(--dark-emerald);
		font-size: 14px;
		font-weight: 400;
	}

	.price {
		font-size: 24px;
		margin: 0 !important;
	}
}
