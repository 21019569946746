@import './mixins.scss';

.ant-form {
	width: 100%;
}

.ant-form-item {
	margin-bottom: 16px;
}

.ant-form-item-label > label {
	@include formLabel;
}

[class*='ant-form-item-label'] {
	@include formLabel;
}

.ant-input-number-disabled {
	background-color: var(--gray2);
	color: var(--gray3);
}

.ant-input[disabled] {
	background-color: var(--gray2);
	color: var(--gray3);
}

.ant-btn-icon {
	display: flex;
	align-items: center;
	justify-content: center;
}
