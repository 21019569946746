.contactSales {
	background-color: var(--corvus-green-light);
	border-color: var(--dark-emerald);
	color: var(--dark-emerald);
	font-weight: 500;

	&:hover {
		background-color: var(--corvus-green-light);
	}
}
