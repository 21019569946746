@import './styles/default-styles.scss';

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	margin: 0;
	background-color: var(--mainWhite);
	min-height: 100vh;
	font-family: 'Jost', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	margin: 0;
	padding: 0;
	font-weight: normal;
}

ol,
ul {
	list-style: none;
}

img {
	max-width: 100%;
	height: auto;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
	--mainWhite: #ffffff;
	--mainRed: #d03900;
	--corvus-green: #00ac8e;
	--corvus-green-secondary: #64e3cf;
	--corvus-green-light: #f2fbf9;
	--light-green: #d8f0eb;
	--corvus-green-hover: #33bda5;
	--dark-emerald: #006162;
	--corvus-black: #1a2026;
	--corvus-black-secondary: #162133;
	--gray: #efeef1;
	--gray2: #e7e6e7;
	--gray3: #5b5b5b;
	--gray4: #8c8c8c;
	--gray5: #d9d9d9;
	--dark-theme-border: #55647c;
}
