.container {
	width: 648px;
	max-width: 648px;

	.titleContainer {
		padding: 16px;
		border-bottom: 1px solid var(--gray);
		width: 100%;

		.title {
			color: var(--dark-emerald) !important;
		}

		.price {
			font-weight: 500;
		}

		.text {
			font-size: 22px;
			font-weight: 400;
		}
	}

	.additionalInfo {
		padding: 16px;
		width: 100%;

		.additionalInfoTitle {
			font-weight: 500;
		}

		.text18 {
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
		}

		.text14 {
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			color: var(--dark-emerald);
		}

		.bold {
			font-weight: 500;
			color: var(--corvus-black);
		}
	}
}
