.container {
	padding: 18px;
	border: 1px solid var(--gray5);
	border-radius: 12px;
	min-width: 327px;
	box-shadow: 6px 6px 15px 0px rgba(50, 90, 81, 0.08);

	.topBlockContainer {
		border-radius: 12px;
		background-color: var(--corvus-green-light);
		padding: 24px;

		.titleContainer {
			width: 100%;
			position: relative;

			.title {
				color: var(--dark-emerald);
				font-size: 28px;
				font-weight: 600;
			}

			.checkbox {
				position: absolute;
				left: 24px;
				transform: translateX(-50%);
			}
		}
	}

	.bottomBlockContainer {
		.featuresTitle {
			margin-bottom: 16px;
			font-weight: 500;
		}
	}
}
