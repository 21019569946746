.container {
	width: 100%;

	.title {
		color: var(--mainWhite);

		text-align: center;
		font-size: 24px;
		font-weight: 600;
	}

	.card {
		border-radius: 12px;
		border: 1px solid var(--gray);
		padding: 12px;
		flex-grow: 1;

		.label {
			font-size: 18px;
			color: var(--corvus-green-secondary);
			font-weight: 600;
		}

		.recommendation {
			text-align: start;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			color: var(--mainWhite);
		}

		.example {
			text-align: start;
			color: var(--corvus-green);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;

			.exampleInner {
				font-weight: 600;
			}
		}
	}
}
