.container {
	border-radius: 12px;
	border: 1px solid var(--gray5);
	width: 100%;

	.header {
		background-color: var(--corvus-green-light);
		padding: 16px 17px;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		margin-bottom: 8px;

		.title {
			font-size: 24px;
			font-weight: 500;
			line-height: 28px;

			color: var(--dark-emerald);
		}
	}

	.content {
		padding: 0 17px 16px 17px;
	}
}
