.container {
	width: 100%;
	text-align: center;
}

@media screen and (max-width: 768px) {
	.container {
		flex-wrap: wrap;
	}
}
