.title {
	line-height: 35px !important;

	.text {
		font-size: 24px;
		font-weight: 400;
	}

	@media (max-width: 768px) {
		width: 100%;
		text-align: center;
	}
}

.checkboxGroup {
	gap: 8px;

	@media (max-width: 768px) {
		justify-content: center;
	}
}
