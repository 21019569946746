.container {
	width: 100%;

	.title {
		font-size: 32px;
		font-weight: 500;
		line-height: 24px;
		margin-bottom: 16px;

		@media (max-width: 768px) {
			width: 100%;
			text-align: center;
		}
	}

	.subTitle {
		color: var(--dark-emerald);
		font-size: 32px;
		line-height: 35px;

		margin-bottom: 24px;

		.subTitleText {
			font-size: 24px;
			font-weight: 400;
		}

		@media (max-width: 768px) {
			text-align: center;
		}
	}

	.content {
		width: 100%;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}
}
