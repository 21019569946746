.container {
	height: 100%;
	.title {
		color: var(--mainWhite);

		text-align: center;
		font-size: 24px;
		font-weight: 600;
	}

	.blockTitle {
		color: var(--corvus-green);
		font-size: 28px;
		font-weight: 500;
		text-align: start;
	}

	.tier {
		color: var(--dark-emerald);
		font-size: 28px;
		font-weight: 500;
		line-height: 28px;
	}

	.tierPrice {
		font-size: 28px;
		font-weight: 500;
		line-height: 22px;

		.tierPriceSub {
			font-size: 22px;
			font-weight: 400;
		}
	}

	.sectionContainer {
		padding-bottom: 16px;
		border-bottom: 1px solid var(--dark-theme-border);
	}

	.text {
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		color: var(--mainWhite);
	}

	.textBold {
		font-size: 18px;
		font-weight: 500;
		line-height: 22px;
	}

	.textSmall {
		font-size: 14px;
		color: var(--corvus-green-secondary);
	}

	.inputNumber {
		width: 40px;
	}

	:global(.ant-input-number-handler-wrap) {
		display: none !important;
	}

	:global(.ant-form-item) {
		margin-bottom: 0;
	}

	:global(.ant-input-password-icon) {
		color: var(--mainWhite) !important;
	}
}
