.container {
	width: 100%;

	.inputWrapper {
		position: relative;
		max-width: 640px;
		width: 100%;
		margin-bottom: 40px;

		.input {
			width: 100%;
			font-size: 16px;
			padding: 0px 95px 0px 16px;
			resize: none;
			border-radius: 32px;
			box-shadow: 0px -1px 9px 0px rgba(198, 198, 198, 0.2);
			background-color: var(--mainWhite);
			color: var(--corvus-black);

			.count {
				font-size: 12px;
				bottom: -25px;
				color: var(--mainWhite);
			}

			.textArea {
				resize: none;
				position: relative;
				height: 67px;
				max-height: 67px;

				@media (max-width: 768px) {
					height: 117px;
					max-height: 117px;
				}
			}
		}

		.button {
			width: 28px;
			min-width: 28px !important;
			height: 28px;
			z-index: 2;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			&.sendButton {
				background-color: var(--dark-emerald);
				right: 16px;
			}

			&.micButton {
				right: 56px;
				background-color: var(--gray);
				color: var(--gray4);

				&.active {
					background-color: var(--dark-emerald);
					color: var(--mainWhite);
				}
			}
		}
	}

	.promptButton {
		border-radius: 20px;
		box-shadow: 0px 20px 6px 0px rgba(214, 214, 214, 0), 0px 13px 5px 0px rgba(214, 214, 214, 0.01),
			0px 7px 4px 0px rgba(214, 214, 214, 0.05), 0px 3px 3px 0px rgba(214, 214, 214, 0.09),
			0px 1px 2px 0px rgba(214, 214, 214, 0.1);
	}

	@media (max-width: 768px) {
		.promptButton,
		.inputWrapper {
			min-width: 260px;
		}
	}
}
