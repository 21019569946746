.container {
	display: flex;
	justify-content: center;

	.card {
		width: 448px;
		text-align: center;

		.title {
			line-height: 40px;
		}

		.text {
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
		}
	}
}
