.container {
	margin-top: 16px;

	.header {
		padding: 12px 0;
		border-bottom: 1px solid var(--gray5);

		.tier {
			color: var(--dark-emerald);
			font-size: 28px;
			font-weight: 500;
			line-height: 28px;
		}

		.tierPrice {
			font-size: 28px;
			font-weight: 500;
			line-height: 22px;

			.tierPriceSub {
				font-size: 22px;
				font-weight: 400;
			}
		}
	}

	.sectionContainer {
		padding-bottom: 16px;
		border-bottom: 1px solid var(--gray5);
	}

	.text {
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		color: var(--corvus-black);
	}

	.textBold {
		font-size: 18px;
		font-weight: 500;
		line-height: 22px;
	}

	.textSmall {
		font-size: 14px;
		color: var(--dark-emerald);
	}

	.addPromotionCode {
		padding-left: 0;
		color: var(--corvus-green);
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;

		&:hover {
			color: var(--corvus-green-hover) !important;
		}
	}

	.applyButton {
		padding: 0;
		color: var(--corvus-green);
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;

		&:hover {
			color: var(--corvus-green-hover) !important;
		}
	}

	.appliedCoupon {
		min-height: 40px;
		min-width: 50%;
		border: 1px solid var(--dark-emerald);
		background-color: var(--corvus-green-light);
		border-radius: 6px;
		padding: 8px 12px;

		.left,
		.right {
			flex: 0 0 auto;
		}

		.center {
			flex: 1;
		}

		.couponRemove {
			cursor: pointer;
		}

		.couponIdText {
			color: var(--dark-emerald);
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
		}
	}
}
