.addPromotionCode {
	padding-left: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	color: var(--dark-emerald);

	&.addPromotionCodeDark {
		color: var(--corvus-green-secondary);
	}

	&:hover {
		color: var(--corvus-green-hover) !important;
	}
}

.applyButton {
	padding: 0;
	color: var(--corvus-green);
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;

	&:hover {
		color: var(--corvus-green-hover) !important;
	}
}

.appliedCoupon {
	min-height: 40px;
	min-width: 50%;
	border: 1px solid var(--dark-emerald);
	background-color: var(--corvus-green-light);
	border-radius: 6px;
	padding: 5px 12px;

	.left,
	.right {
		flex: 0 0 auto;
	}

	.center {
		flex: 1;
	}

	.couponRemove {
		cursor: pointer;
		color: var(--dark-emerald);
	}

	.couponIdText {
		color: var(--dark-emerald);
		font-size: 14px;
		font-weight: 500;
		line-height: 24px;
	}

	:global(.ant-btn-icon) {
		color: var(--dark-emerald) !important;
	}
}

.text {
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	color: var(--mainWhite);
}
